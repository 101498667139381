import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {onLoginExpired} from '@templates/Login/login-slice'
import {logout} from '@templates/Logout/logout-slice'
import {CommonProps} from '@utils/types'

/**
 * Logout
 */
const Logout: React.FC<CommonProps> = () => {
	const dispatch = useDispatch()

	const globalError = useSelector((state: RootState) => state.error.error)
	const logoutSuccess = useSelector((state: RootState) => state.logout.success)

	useEffect(() => {
		if (logoutSuccess) {
			dispatch(navigate(`/login`))
		}
	}, [logoutSuccess])

	useEffect(() => {
		const globalErrorJson = JSON.parse(globalError!)
		if (globalErrorJson && globalErrorJson.statusCode === 401) {
			dispatch(onLoginExpired())
		}

		dispatch(logout())
	}, [dispatch])

	return <div id="logout-template" />
}

Logout.propTypes = {}

export default Logout
